import styles from "./CheckInsurancePage.module.scss";

export const CanPrescribeAndAcceptInsurance = () => {
  return (
    <div className={styles.response}>
      <span>Great news!</span>
      <span>
        ✅ <b>Prescriptions:</b> We can prescribe in your state.
      </span>
      <span>
        ✅ <b>Insurance:</b> Your plan may be in-network, but{" "}
        <b>coverage depends on your specific plan details.</b>
      </span>
      <span>
        To confirm your coverage, please email photos of the front and back of your insurance card
        to{" "}
        <a className={styles.link} href="mailto:support@neurahealth.co">
          support@neurahealth.co
        </a>
        , and we'll be happy to check for you.
      </span>
    </div>
  );
};

export const CanPrescribeAndCannotAcceptInsurance = () => {
  return (
    <div className={styles.response}>
      <span>
        ✅ <b>Prescriptions:</b> A Neura clinician can prescribe medications in your state.
      </span>
      <span>
        ❌ <b>Insurance:</b> We're not currently in-network with your insurance.
      </span>
      <span>
        Please use{" "}
        <a className={styles.link} href="https://form.jotform.com/250165562551151">
          this form
        </a>{" "}
        to enter your insurance details, and we’ll notify you when your plan is in-network.
      </span>
      <span>
        Currently, your cost would be $199 for the first 45 min visit and $144 for each 20 min
        follow-up, in addition to the $39/month membership fee. More info{" "}
        <a className={styles.link} href="https://www.neurahealth.co/pricing">
          here
        </a>
        .
      </span>
      <span>
        We can also provide a superbill to submit to your insurance for potential out-of-network
        reimbursement.
      </span>
      <span>
        We typically have appointment availability within a week, while getting one with an
        in-network provider could take months or longer. Your time and well-being are valuable!
      </span>
    </div>
  );
};

export const CanPrescribeAndNoInsuranceInfo = () => {
  return (
    <div className={styles.response}>
      <span>
        ✅ <b>Prescriptions:</b> A Neura clinician can prescribe medications in your state.
      </span>
      <span>
        <b>Without insurance</b>, your visit would be $199 for the first 45-minute visit and $144
        for each 20-minute follow-up, in addition to the $39/month membership fee.
      </span>
      <span>
        More info{" "}
        <a className={styles.link} href="https://www.neurahealth.co/pricing">
          here
        </a>
        .
      </span>
    </div>
  );
};

export const CannotPrescribe = () => {
  return (
    <div className={styles.response}>
      <span>
        ❌ <b>Prescriptions:</b> You are in a state where we can't currently prescribe medication or
        accept insurance.
      </span>
      <span>
        <b>We can still help you</b> ✅
      </span>
      <span>
        You can hold a video visit with a Neura provider, who will create a personalized treatment
        plan, which may include recommendations for medication and/or imaging, labs, etc.
      </span>
      <span>
        With your permission, we'll contact your current PCP to share our visit notes and
        recommendations. They can then prescribe any necessary treatment. In most cases, they are
        willing to do this.
      </span>
      <span>
        Alternatively, if you can travel to a{" "}
        <a className={styles.link} href="https://neurahealth.co/prescriptions">
          state where we can prescribe for your condition
        </a>{" "}
        at least once a year, we can send your initial prescription or test order to that state and
        then manage your care remotely from anywhere for the rest of the year.
      </span>
    </div>
  );
};
